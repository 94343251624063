import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";

import {
  type PaddleEventData,
  type CheckoutEventsData,
  type PricePreviewParams,
} from "@paddle/paddle-js";
import axios from "axios";
import { useSession } from "next-auth/react";

import { PriceId } from "@musicfy/contants/Subscriptions";
import { env } from "@musicfy/env.mjs";
import { useAnalytics } from "@musicfy/libs/AnalyticsProvider";
// import BlackFridayPricing from "@musicfy/libs/PaddleProvider/screens/BlackFridayPricing";
import Checkout from "@musicfy/libs/PaddleProvider/screens/Checkout";
import Pricing from "@musicfy/libs/PaddleProvider/screens/Pricing";
import UpdatePayment from "@musicfy/libs/PaddleProvider/screens/UpdatePayment";
import Upgrade from "@musicfy/libs/PaddleProvider/screens/Upgrade";
import {
  type PaddleContext,
  type PaddleEventCallback,
  type PaddleCheckoutContext,
  type PaddleScreen,
  type PaddleUpdatePaymentContext,
  type UpdatePaymentEventData,
  PaddleScreens,
  type PaddlePricesContext,
  type PaddleUpgradePreviewContext,
  type PaddleTransactionsContext,
  type PricePreviewData,
} from "@musicfy/libs/PaddleProvider/types";
import PaddleLoader from "@musicfy/scripts/PaddleLoader";
import {
  type PaddleTransaction,
  type PaddleSubscriptionUpdatePreview,
} from "@musicfy/types/paddle-server";
import { api } from "@musicfy/utils";

import { useSubscriptionContext } from "../SubscriptionProvider";

export const PADDLE_TARGET_FRAME = "paddle-container";

const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;

const PADDLE_SCREENS: Record<PaddleScreen, () => JSX.Element> = {
  [PaddleScreens.CHECKOUT]: Checkout,
  [PaddleScreens.UPDATE_PAYMENT]: UpdatePayment,
  [PaddleScreens.PRICING]: Pricing,
  // [PaddleScreens.PRICING]: BlackFridayPricing,
  [PaddleScreens.UPGRADE]: Upgrade,
};

const Context = createContext<PaddleContext | undefined>(undefined);

export function usePaddleContext() {
  const paddleContext = useContext(Context);

  if (!paddleContext) {
    throw new Error("usePaddleContext must be used within a PaddleProvider");
  }

  return paddleContext;
}

const PaddleProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const { trackEvent } = useAnalytics();
  const { subscription } = useSubscriptionContext();
  const { status, data } = useSession();

  const [activePaddleScreen, setActivePaddleScreen] =
    useState<PaddleScreen | null>(null);

  const [origin, setOrigin] = useState<string | undefined>();
  const [isCheckoutUpdating, setIsCheckoutUpdating] = useState(false);
  const [isUpgradeUpdating, setIsUpgradeUpdating] = useState(false);

  const [checkoutData, setCheckoutData] = useState<CheckoutEventsData>();
  const [checkoutError, setCheckoutError] = useState<PaddleEventData>();
  const [pricesData, setPricesData] = useState<PricePreviewData>();
  const [upgradePreviewData, setUpgradePreviewData] =
    useState<PaddleSubscriptionUpdatePreview>();
  const [transactions, setTransactions] = useState<PaddleTransaction[] | null>(
    null
  );

  const [updatePaymentData, setUpdatePaymentData] =
    useState<UpdatePaymentEventData>();
  const [updatePaymentError, setUpdatePaymentError] =
    useState<PaddleEventData>();

  const isFetchingSubscriptionRef = useRef(false);

  /** Non paddle frame related callbacks */
  const startCheckoutFlow = useCallback(
    (origin?: string) => {
      setActivePaddleScreen(PaddleScreens.PRICING);

      /* Analytics */
      setOrigin(origin);
      trackEvent("Pricing Page Viewed", {
        origin: origin,
      });
    },
    [trackEvent]
  );

  /** Set up incoming paddle event listeners */
  const paddleEventCallbacksRef = useRef<PaddleEventCallback[]>([]);

  const subscribeToPaddleEvents = useCallback(
    (callback: PaddleEventCallback) => {
      paddleEventCallbacksRef.current.push(callback);
    },
    []
  );

  const unsubscribeFromPaddleEvents = useCallback(
    (callback: PaddleEventCallback) => {
      paddleEventCallbacksRef.current = paddleEventCallbacksRef.current.filter(
        (listener) => listener !== callback
      );
    },
    []
  );

  const paddleEventCallback = useCallback((event: PaddleEventData) => {
    paddleEventCallbacksRef.current.forEach((callback) => callback(event));
  }, []);

  const onPaddleSetup = useCallback(async () => {
    const items = Object.values(PriceId)
      .flatMap((priceId) => [{ priceId: priceId, quantity: 1 }])
      .filter((price) => {
        return !!price.priceId;
      });

    if (!window.Paddle) {
      return;
    }

    let ipAddress: string | undefined;

    try {
      const location = await axios.get<{ country: string; ip: string }>(
        "https://api.ipify.org?format=json"
      );

      ipAddress = location.data.ip;
    } catch (err) {
      console.error(err);
    }

    const isNewUser = !!data?.user
      ? Date.now() - new Date(data.user.createdAt).getTime() < ONE_DAY_IN_MS * 7
      : false;

    const isEligibleForDiscount = !subscription && isNewUser;
    const discountId =
      env.NEXT_PUBLIC_VERCEL_ENV === "production"
        ? "dsc_01hs1ghkgstga49dqm0r3wpys2"
        : "dsc_01hhkrg40tqeb2cdzkwzkthp67";

    if (isEligibleForDiscount && !!discountId) {
      console.log(true);
    }

    const requests: PricePreviewParams = {
      items: items,
      customerIpAddress: ipAddress,
      // discountId: isEligibleForDiscount ? discountId : undefined,
    };

    try {
      const res = await window.Paddle.PricePreview(requests);
      setPricesData(res.data);
    } catch (err) {
      console.error(err);
    }
  }, [data?.user, subscription]);

  /** Set up checkout context */
  const checkoutContext: PaddleCheckoutContext = {
    checkoutData,
    setCheckoutData,
    checkoutError,
    setCheckoutError,
    isFetchingSubscriptionRef,
    isCheckoutUpdating,
    setIsCheckoutUpdating,
  };

  /** Set up update payments context */
  const updatePaymentContext: PaddleUpdatePaymentContext = {
    updatePaymentData,
    setUpdatePaymentData,
    updatePaymentError,
    setUpdatePaymentError,
  };

  /** Set up prices context */
  const pricesContext: PaddlePricesContext = {
    pricesData,
  };

  /** Set up upgrade preview context */
  const upgradePreviewContext: PaddleUpgradePreviewContext = {
    upgradePreviewData,
    setUpgradePreviewData,
    isUpgradeUpdating,
    setIsUpgradeUpdating,
  };

  /** Set up transactions context for subscribed users */
  const getCustomerTransactions =
    api.subscription.getCustomerTransactions.useMutation({
      onSuccess: (data) => {
        setTransactions(data);
      },
      onError: () => {
        setTransactions([]);
      },
    });

  const transactionsContext: PaddleTransactionsContext = {
    transactions: transactions,
    isLoading: !transactions && getCustomerTransactions.isLoading,
    getCustomerTransactions: getCustomerTransactions,
  };

  /** Set up paddle context */
  const paddleContextValue: PaddleContext = {
    checkoutContext,
    pricesContext,
    transactionsContext,
    updatePaymentContext,
    upgradePreviewContext,
    subscribeToPaddleEvents,
    unsubscribeFromPaddleEvents,
    setActivePaddleScreen,
    startCheckoutFlow,
    origin,
    setOrigin,
  };

  const PaddleScreen = activePaddleScreen
    ? PADDLE_SCREENS[activePaddleScreen]
    : null;

  return (
    <Context.Provider value={paddleContextValue}>
      {children}
      {status !== "loading" && (
        <PaddleLoader
          eventCallback={paddleEventCallback}
          onSetup={onPaddleSetup}
        />
      )}

      {!!PaddleScreen && <PaddleScreen />}
    </Context.Provider>
  );
};

export default PaddleProvider;
