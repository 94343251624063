// import DiscountBanner from "./DiscountBanner";
// import BlackFridayBanner from "./BlackFridayBanner";
import FailedPaymentBanner from "./FailedPaymentBanner";

const NotificationBanners = (): JSX.Element | null => {
  return (
    <>
      {/* <DiscountBanner /> */}
      {/* <BlackFridayBanner /> */}
      <FailedPaymentBanner />
    </>
  );
};

export default NotificationBanners;
